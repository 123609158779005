.container {
  display: flex;
  flex-direction: row;

  @media (--tablet) {
    flex-direction: column;
  }
}

.main {
  flex: 2;
}

.secondary {
  flex: 1.5;

  @media (--tablet) {
    width: 100%;
  }
}

.box {
  background-color: var(--color--primary-dark);
  padding: 1.6rem;
  margin: 1.6rem;
  border-radius: 8px;

  @media (--mobile) {
    margin: 0;
    border-radius: 0;
  }
}

.margin {
  margin: 1.6rem;
}

.requestCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.imgContainer {
  width: 6.4rem;

  & > .img {
    width: 100%;
  }
}

.title {
  flex: 1;
  margin: 0 1.6rem;
}

.tabs {
  margin: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;

  & > * {
    flex: 1;
  }
}

.tab {
  padding: 1.6rem;
  font-size: var(--font-size--title);
  background-color: var(--color--primary-darkest);
  color: var(--color--white);
  border: none;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &.selected::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: var(--color--white);
  }
}