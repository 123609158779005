html {
  height: 100%;
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-size: var(--font-size--body);
  font-family: var(--font-family);
  font-weight: 300;
  line-height: 1.25;
  background-color: var(--color--primary-light);
  color: var(--color--white);

  &.hide-overflow,
  &.hide-overflow--nested {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}

input,
textarea,
select,
button {
  font-family: var(--font-family);

  &:disabled {
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
    box-shadow: 0 0 1px 2px var(--color--secondary);
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 6rem;
  line-height: 1.05;
}

h2 {
  font-size: 3rem;
  font-weight: 700;
}

h3 {
  font-size: 2rem;
  font-weight: 400;
}

h4 {
  font-size: 1.6rem;
  margin-top: 0.8rem;
}

a {
  color: inherit;
  text-decoration: none;
  font-weight: 600;
}
