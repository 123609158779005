.container {
  margin: 1.6rem;
}

.header {
  text-align: center;
}

.title {
  display: block;
  font-size: var(--font-size--title);
  font-weight: 700;
  margin: 2.4rem 0;
}

.content {
  padding: 0.8rem;
}

.section {
  margin-bottom: 1.6rem;
}

.label {
  display: block;
  font-size: var(--font-size--caption);
  color: var(--color--grey-dark);
}

.value {
  display: block;
  font-weight: 700;
}

.disclaimer {
  display: block;
  text-align: center;
  font-size: var(--font-size--caption);
  color: var(--color--grey-dark);
}