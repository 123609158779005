.accordion {
  border-top: 1px solid var(--color--white);
  margin: 1.6rem 0;
}

.accordionButton {
  width: 100%;
  border: none;
  background: none;
  text-align: left;
  font-size: 1.8rem;
  padding: 1.6rem;
  color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
}

.chevron {
  transition: all 0.2s ease;
  width: 1.2rem;
  height: 1.2rem;
}

.content {
  transition: max-height 0.2s ease;
  max-height: 0;
  overflow: hidden;
  padding: 0 1.6rem;
  border-bottom: 1px solid var(--color--white);
}

.expanded {
  & .chevron {
    transform: rotate(180deg);
  }

  & .content {
    max-height: 20rem;
    padding: 1.6rem;
  }
}