.container {
  margin: 1.6rem;
}

.logoContainer {
  text-align: center;
  margin-bottom: 1.6rem;
}

.disclaimer {
  display: block;
  margin-top: 1.6rem;
  color: var(--color--grey-dark);
  text-align: center;
}