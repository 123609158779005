:root {
  --font-family: 'Source Sans Pro', sans-serif;
  --font-size--title: 2rem;
  --font-size--body: 1.4rem;
  --font-size--caption: 1.2rem;

  --color--black: #000;
  --color--white: #fff;
  --color--error: #f00;
  --color--warning: #ffa500;
  --color--grey: #EBEFF5;
  --color--grey-dark: #818E96;
  --color--primary: #293842;
  --color--primary-dark: #1d2c36;
  --color--primary-darkest: #101b22;
  --color--primary-light: #274355;
  --color--secondary: #FF0080;
}