.container {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--color--grey-dark);
  position: relative;

  &.hasPrefix {    
    &::before {
      content: attr(data-prefix);
      position: absolute;
      left: 0.8rem;
      top: 50%;
      transform: translateY(-50%);
      color: var(--color-grey);
    }
  }
}

.input {
  width: 100%;
  border: none;
  border-radius: inherit;
  padding: 1.2rem 0.8rem;
}

.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.showPasswordButton {
  position: absolute;
  right: 0.8rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.4rem;
}