.section {
  padding: 4.8rem 1.6rem;
  background-color: var(--color--primary-dark);

  &.hero {
    background-repeat: no-repeat;
    background-size: cover;
    position: center;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 -4rem;

  & > * {
    margin: 0 4rem;
  }

  @media (--mobile) {
    flex-direction: column;
  }
}

.imgContainer {
  flex: 1;
}

.img {
  width: 100%;
}

.content {
  flex: 1;
}

.title {
  @media (--mobile) {
    font-size: 3.2rem;
  }
}

.ctaButton {
  width: 100%;
}

.bookies {
  background-color: var(--color--primary-darkest);
  padding: 1.6rem 0;
}

.bookiesList {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    flex: 1;
    text-align: center;
  }
}

.bookieLogo {
  height: 4rem;

  @media (--mobile) {
    height: 2.4rem;
  }
}

.list {
  padding: 1.6rem 0;
  margin: 0;
  counter-reset: index;
  list-style: none;

  & > li {
    position: relative;
    padding-left: 5.6rem;
    margin-bottom: 3.2rem;

    &::before {
      content: counter(index);
      counter-increment: index;
      position: absolute;
      left: 0;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background-color: var(--color--primary);
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
    }
  }
}

.faq {
  background-color: var(--color--primary-light);

  & h2 {
    text-align: center;
  }
}
