.container {
  margin-bottom: 0.8rem;
}

.label {
  display: block;
  margin-bottom: 0.4rem;
  font-weight: 700;
}

.row {
  display: flex;
  flex-direction: row;
  margin: 0 -0.8rem;

  & > * {
    margin: 0 0.8rem;
  }
}

.errorText {
  display: block;
  font-size: var(--font-size--caption);
  color: var(--color--error);
  margin-top: 0.4rem;
}

.hintText {
  display: block;
  font-size: var(--font-size--caption);
  color: var(--color--grey-dark);
  margin-top: 0.4rem;
}