.button {
  font-size: var(--font-size--body);
  border-radius: 0.4rem;
  border: 0.1rem solid;
  transition: all 0.2s ease-in-out;
  padding: 1.2rem 1.6rem;

  &:disabled {
    opacity: 0.75;
  }

  &:not(:disabled):hover {
    cursor: pointer;
  }
}

.theme--default {
  background-color: var(--color--primary);
  border-color: var(--color--white);
  color: var(--color--white);

  &:not(:disabled):hover {
    background-color: var(--color--primary-dark);
  }
}

.theme--secondary {
  background-color: var(--color--white);
  border-color: var(--color--black);
  color: var(--color--black);

  &:not(:disabled):hover {
    background-color: var(--color--grey);
  }
}

.theme--none {
  background-color: transparent;
  border-color: transparent;
  color: inherit;

  &:not(:disabled):hover {
    background-color: var(--color--grey);
    color: var(--color--black);
  }
}

.buttonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
