.container {
  padding: 1.6rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.6rem 0;
}

.imgContainer {
  width: 4.8rem;
}

.img {
  width: 100%;
}

.title {
  display: block;
  margin-left: 0.8rem;
  font-size: var(--font-size--title);
  font-weight: 700;
}