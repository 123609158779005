.container {
  padding: 1.6rem;
}

.table {
  border-collapse: collapse;
  margin: 1.6rem 0;

  & th,
  & td {
    border: 1px solid #999;
    padding: 0.4rem;
  }

  & tbody th {
    text-align: left;
    vertical-align: top;
  }
}
