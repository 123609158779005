.container {
  margin: 1.6rem;
}

.header {
  text-align: center;
}

.title {
  display: block;
  font-size: var(--font-size--title);
  font-weight: 700;
  margin: 2.4rem 0;
}

.subtitle {
  display: block;
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  font-weight: 700;
}

.addressContainer {
  background-color: var(--color--grey);
  padding: 1.6rem;
  border-radius: 4px;
  margin: 1.6rem 0;
}

.name {
  display: block;
  font-weight: 700;
}

.address {
  display: block;
  margin: 1.6rem 0;
}

.disclaimer {
  font-size: var(--font-size--caption);
  color: var(--color--grey-dark);
}