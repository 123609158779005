.container {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--mobile) {
    align-items: flex-start;
  }
}

.modal {
  background-color: var(--color--white);
  border-radius: 4px;
  width: 48rem;
  overflow: auto;
  max-height: 100%;
  color: var(--color--black);

  @media (--mobile) {
    width: 100%;
    border-radius: 0;
  }
}

.modalHeader {
  background-color: var(--color--primary);
  color: var(--color--white);
  padding: 0 1.6rem;
  height: 6.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 2.4rem;
  font-weight: 700;
}