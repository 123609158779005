.bookie {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bookieDetail {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name {
  display: block;
  margin: 0 0.4rem;
  font-weight: 600;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 -0.4rem;

  & > * {
    margin: 0 0.4rem;
  }
}

.button {
  background: none;
  border: 1px solid var(--color--black);
  padding: 0.4rem 0.8rem;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
}

.list > * {
  margin: 1.6rem 0;
}
