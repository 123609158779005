.container {
  display: flex;
  align-items: center;
}

.checkbox {
  width: 2.4rem;
  height: 2.4rem;
}

.label {
  margin-left: 0.8rem;
}