.container {
  margin: 1.6rem;
}

.logo {
  display: block;
  margin: 0 auto 1.6rem;
}

.page {
  display: none;

  &.open {
    display: block;
  }
}

.disclaimer {
  display: block;
  margin-top: 1.6rem;
  color: var(--color--grey-dark);
  text-align: center;
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressLink {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 3.2rem;
  width: 3.2rem;
  text-align: center;
  background-color: var(--color--grey);
  border: 3px solid var(--color--grey);
  margin: 0 0.8rem;
  position: relative;

  &.selected {
    background-color: var(--color--white);
    border-color: var(--color--secondary);
  }

  &.completed {
    background-color: var(--color--secondary);
    border-color: var(--color--secondary);
    color: var(--color--white);
  }
}