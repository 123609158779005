.alert {
  background-color: var(--color--grey);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem 1.6rem;
  margin: 0.8rem 0;
}

.icon {
  margin-right: 0.8rem;
}

.error {
  & > .icon {
    color: var(--color--error);
  }
}

.warning {
  & > .icon {
    color: var(--color--warning);
  }
}

.message {
  flex: 1;
}